
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-paniconfiancaNavTheme {
  /* background-color: maroon; */
  background-image: linear-gradient(to right,#991200,#f96400)
}

.bg-paniconfiancaThemeTransparent {
  background-image : linear-gradient(to right,#991200c0,#f96400c0)
}

.pageTitle {
  font-size: 32px;
  margin-bottom : 25px;
}

@media print {
    .schedule {
      font-size: 26px;
      width: 100% !important;
      display: flex;
      justify-content: space-around;
      margin-left: 0% !important;
    }

    .schedule-weekday {
        width: 80% !important;
    }

    .schedule-hours {
      text-align: right !important;
      margin-bottom: 20px !important;
    }
}